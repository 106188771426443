import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { intersection } from 'lodash-es';
import {
    AccessHelper,
    ApplicationInsightsService,
    AppLoginMetric,
    EMPTY_GUID,
    TenantModeEnum,
    UserTypeEnum
} from '@summize/shared/core';

import { AssetLogo, Environment, User } from '../types';
import { SessionStorageService } from './session-storage.service';
import { SummizeStorage } from './summize-storage';

@Injectable({ providedIn: 'root' })
export class UserService {

    private refreshInterval;

    private refeshIntervalPeriod = 4 * 60 * 60 * 1000;

    constructor(
        private http: HttpClient,
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private appInsights: ApplicationInsightsService,
        @Inject(Environment) private environment) { }

    public async loadUser(): Promise<User | void> {

        const user = await this.getUser();

        if (user === undefined) {

            this.clearSession();

            return;

        }

        SummizeStorage.setLocalItem('user', JSON.stringify(user));

        return user;

    }

    public async goToRootPage() {

        const savedPage = this.sessionStorageService.getItem('login-redirect-page');

        this.sessionStorageService.removeItem('login-redirect-page');

        if (savedPage?.length > 0) {

            this.router.navigateByUrl(savedPage);
            return;
        }

        const root = await this.getRootPage() || '/root';

        this.router.navigate([root]);
    }

    public async getUser(): Promise<User> {

        const existing = this.getUserValue();

        if (existing !== undefined && existing !== null) {

            return Promise.resolve(this.getUserValue());

        }

        return await this.refreshSession();

    }

    public getToken(): string {

        return SummizeStorage.getLocalItem('token') as string;

    }

    public setToken(token: string) {

        SummizeStorage.setLocalItem('token', token);
    }

    public setUser(user: User) {

        SummizeStorage.setLocalItem('user', JSON.stringify(user));

    }

    public async getManagePage(): Promise<any> {

        const user: User = await this.getUser();

        if (user.isClientMatterEnabled === false) {

            return `/my-contracts/clients/${user.tenantId}/matters/${user.tenantId}`;

        }

        return '/contracts';

    }

    public async getRootPage(): Promise<string> {

        const user: User = await this.getUser();

        if (user.isClientMatterEnabled === false) {

            return `/my-contracts/clients/${user.tenantId}/matters/${user.tenantId}`;

        }

        if (user.userType === UserTypeEnum.Requestor) {

            return 'quick-summary/repository';

        }

        return '/contracts/overview';

    }

    public hasFeatureFlag(flag: string): boolean {

        const flags = [...this.environment.featureFlags, ...this.getFeatureFlags()];

        return flags.find(f => f === flag) !== undefined;

    }

    public hasClaim(claim: string): boolean {

        return this.hasClaims([claim]);

    }

    public hasClaims(claims: string[], all: boolean = true): boolean {

        const userClaims = this.getClaims();

        if (all === true) {

            const intersect = intersection(claims, userClaims);

            return (intersect.length === claims.length);

        } else {

            const intersect = intersection(claims, userClaims);

            return (intersect.length > 0);

        }

    }

    public getIsAdmin(): boolean {

        const user = this.getUserValue();

        return user?.isAdmin ?? false;

    }

    public clearSession() {

        SummizeStorage.removeLocalItem('token');

        SummizeStorage.removeLocalItem('user');

        SummizeStorage.removeLocalItem('correlationId');

        SummizeStorage.removeLocalItem('okta-pkce-storage');

        SummizeStorage.removeLocalItem('okta-cache-storage');

        SummizeStorage.removeLocalItem('okta-token-storage');

    }

    public getUserValue(): User {

        const u: string = SummizeStorage.getLocalItem('user') as string;

        return JSON.parse(u) as User;

    }

    public async refreshSession(redirect = true): Promise<User> {

        try {

            if (this.refreshInterval !== undefined) {

                clearInterval(this.refreshInterval);

            }

            this.refreshInterval = setInterval(
                async () => await this.refreshSession(),
                this.refeshIntervalPeriod
            );

            const sessionResponse: any
                = await this.http.get(`${this.environment.apiUrl}/common/api/1.0/session`).toPromise();

            SummizeStorage.setLocalItem('pdfKey', sessionResponse.tenant.pdfKey);

            const apiUrl = this.environment.apiUrl.startsWith('https') ?
                sessionResponse.tenant.apiBaseUrl.replace('http://', 'https://') : sessionResponse.tenant.apiBaseUrl;

            const user: User = {
                apiBaseUrl: apiUrl,
                claims: sessionResponse.user.claims,
                clientId: sessionResponse.user.clientId,
                companyLogoUrl: sessionResponse.tenant.logoUrl,
                departmentId: sessionResponse.user.departmentId,
                departmentName: sessionResponse.user.departmentName,
                email: sessionResponse.user.email,
                featureFlags: sessionResponse.user.featureFlags,
                firstName: sessionResponse.user.firstName,
                hasCompletedRegistration: sessionResponse.tenant.hasCompletedRegistration,
                hasExpired: sessionResponse.tenant.hasExpired,
                isClientMatterEnabled: sessionResponse.tenant.isClientMatterEnabled,
                showGenericClientMatterLabels: sessionResponse.tenant.showGenericClientMatterLabels,
                isAdmin: sessionResponse.user.isAdmin,
                lastName: sessionResponse.user.lastName,
                matterId: sessionResponse.user.matterId,
                tenantId: sessionResponse.tenant.tenantId,
                tenantName: sessionResponse.tenant.tenantName,
                multiTenantId: sessionResponse.tenant.multiTenantId,
                userId: sessionResponse.user.userId,
                userType: sessionResponse.user.userType,
                isFullTextSearchEnabled: sessionResponse.tenant.isFullTextSearchEnabled,
                isSummizeAuthentication: sessionResponse.user.isSummizeAuthentication,
                isAIEnabled: sessionResponse.tenant.isAIEnabled,
                isPremiumPlaybookEnabled: sessionResponse.tenant.isPremiumPlaybookEnabled,
                isPremiumPlaybookAnalyticsEnabled: sessionResponse.tenant.isPremiumPlaybookAnalyticsEnabled,
                isMultitenancySearchEnabled: sessionResponse.tenant.isMultitenancySearchEnabled,
                connectors: sessionResponse.tenant.connectors,
                timeZoneId: sessionResponse.tenant.timeZoneId,
                tenantMode: sessionResponse.tenant.tenantMode
            };

            const isRestricted = AccessHelper.CanAccessApplication(user.featureFlags, user.claims) === false;

            if (isRestricted === true && this.router.url !== 'no-access') {

                this.router.navigateByUrl('no-access');

                return;

            }

            if (user.companyLogoUrl === undefined || user.companyLogoUrl === null || user.companyLogoUrl.length === 0) {

                const logo = await this.http.get<AssetLogo>(`${user.apiBaseUrl}1.0/assets/logo`).toPromise();

                user.companyLogoUrl = logo.logoUrl;

            }

            user.companyLogoUrl = user.companyLogoUrl + '?t' + new Date().getTime();

            this.setUser(user);

            this.setIsSummizeAuthentication(user.isSummizeAuthentication);

            this.appInsights.setUserId(user.userId);

            this.appInsights.logMetric(AppLoginMetric, 1, {
                tenantId: user.tenantId,
                userId: user.userId
            });

            return user;

        } catch (err) {

            if (redirect === true) {

                this.router.navigateByUrl('/problem');
            }

            clearInterval(this.refreshInterval);

            throw err;

        }

    }

    public setIsSummizeAuthentication(isSummizeAuthentication) {

        SummizeStorage.setLocalItem('isSummizeAuthentication', isSummizeAuthentication);

    }

    public getIsSummizeAuthentication() {

        return SummizeStorage.getLocalItem('isSummizeAuthentication') === 'true';

    }

    public getClaims(): string[] {

        const user = this.getUserValue();

        return user?.claims ?? [];

    }

    public isRequestorUser() {

        const user = this.getUserValue()

        return user?.userType === UserTypeEnum.Requestor;

    }

    private getClientId(): string | null {

        const user = this.getUserValue();

        if (!user || !user.clientId || user.clientId === EMPTY_GUID) {

            return null;

        }

        return user?.clientId;

    }

    private getMatterId(): string | null {

        const user = this.getUserValue();

        if (!this.getClientId() || !user.matterId || user.matterId === EMPTY_GUID) {

            return null;

        }

        return user?.matterId;

    }

    private getFeatureFlags(): Array<string> {

        const user = this.getUserValue();

        if (!user) {

            return [];

        }

        return user?.featureFlags ?? [];

    }

    public isPocUser() {

        const user = this.getUserValue();

        return user.tenantMode === TenantModeEnum.Poc
            && !user.email.toLowerCase().endsWith('summize.com');

    }
}
