<div class="width-placeholde">
    <!-- SteO - We use this to still reserve the space required by the collapsed panel or the pdf view will reflow -->
</div>

<div [class.expanded]="isExpanded === true"
    [class.small-display]="displayMode !== ToolPanelDisplayMode.Standalone && isOnSmallDisplay === true"
    class="tool-panel">

    <div *ngIf="panels && hidePanelTabs !== true" class="tool-panel-bar">
        <ng-container *ngFor="let panel of panels">
            <div *ngIf="shouldShow(panel)" [class.selected]="panel === selected" [matTooltip]="panel.tooltip"
                matTooltipPosition="left" matTooltipClass="smz-tooltip" (click)="setSelectedPanel(panel)"
                class="panel-item clickable">
                <app-icon [customSVG]="panel === selected ? panel.iconSelectedSVG :panel.iconSVG" [icon]="panel.icon"
                    [attr.data-cy]="panel.id" [attr.data-testid]="panel.id"></app-icon>
                <div *ngIf="panel.badgeValue !== undefined && panel.badgeValue !== 0"
                    [class.overflow]="panel.badgeValue > 9" class="badge">
                    {{panel.badgeValue > 9 ? '9+' : panel.badgeValue}}
                </div>
            </div>
        </ng-container>
        <div *ngIf="displayMode !== ToolPanelDisplayMode.Standalone" class="panel-seperator">&nbsp;</div>
        <div *ngIf="displayMode === ToolPanelDisplayMode.Standard"
            matTooltip="{{isExpanded === true ? 'Collapse' : 'Expand'}}" matTooltipPosition="left"
            matTooltipClass="smz-tooltip" class="panel-item clickable collapse-control">
            <app-icon (click)="toggleExpanded()"
                [icon]="isExpanded === true ? 'chevron-forward-circle-outline' :'chevron-back-circle-outline'">
            </app-icon>
        </div>
        <div *ngIf="displayMode === ToolPanelDisplayMode.Sidebar" matTooltip="Close" (click)="onClose.emit(true)"
            matTooltipPosition="left" matTooltipClass="smz-tooltip" class="panel-item clickable collapse-control">
            <app-icon (click)="toggleExpanded()" [icon]="'close-circle-outline'">
            </app-icon>
        </div>
    </div>

    <div *ngIf="isExpanded === true || hidePanelTabs === true" class="tool-panel-content">
        <div *ngIf="isLoading === true" class="loader">
            <app-loading-spinner visible="true" type="pale"></app-loading-spinner>
        </div>
        <div *ngIf="contract?.isLatest === false && (selected?.isVersionSpecific === undefined || selected?.isVersionSpecific === true)"
            class="latest-warning">
            This version is read-only, please select the latest version to make changes.
        </div>

        <div *ngIf="displayMode === ToolPanelDisplayMode.Sidebar" class="latest-warning link">
            <a class="link" [routerLink]="getContractLink(row)">{{contract.documentName}} </a>
        </div>
        <div [class.visible]="isLoading === false" class="panel-comp-host">
            <ng-template #host></ng-template>
        </div>
    </div>

</div>