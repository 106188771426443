import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { AppComponent } from '@summize/shared/core';

export interface ElipsisCommand {
    text: string;
    icon?: string;
    claim?: string;
    onClick: Function;
    ngIf?: Function;
    isDisabled?: Function;
    testId?: string;
}

@Component({
    selector: 'app-ellipses-menu',
    templateUrl: 'ellipses-menu.html',
    styleUrls: ['./ellipses-menu.scss']
})
export class EllipsesMenuComponent extends AppComponent implements OnDestroy {

    @ViewChild(MatMenuTrigger)
    public trigger: MatMenuTrigger;

    @Input()
    public commands: Array<ElipsisCommand>;

    @Input()
    public context: any;

    @Input()
    public icon: string = 'ellipsis-horizontal-outline';

    @Input()
    public style: string;

    public fire(command: ElipsisCommand): void {

        if (command.onClick !== undefined) {

            command.onClick(this.context);

        }

    }

    public ngOnDestroy(): void {

        this.commands = undefined;

        this.context = undefined;

        super.ngOnDestroy();

    }

    public open() {

        if (this.trigger !== undefined) {

            this.trigger.openMenu();

        }
        
    }

}