import { NgModule, ErrorHandler, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { QuillModule } from 'ngx-quill';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';

import { ApplicationInsightsService, BaseTenantService, getLocale, GlobalContextService, NavigationService, TelemetryService } from '@summize/shared/core';
import { Environment, EventService, SummizeStorage } from '@summize/shared/framework';
import { AskAiPanelModule, CorePipesModule, PlatformModule } from '@summize/shared/components-v2';
import { environment } from '@summize/environment';

import { AppErrorHandler } from './app.errorhandler';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core';
import { appReducers } from './store/reducers/app.reducers';
import { LoginComponent } from './modules/public-site/pages/login/login.component';
import { AppShellModule } from './core/components/app-shell/app-shell.module';
import { RegionGuard } from './core/guards/region-guard.service';

const envFactory = () => {

  SummizeStorage.setSessionItem(
    BaseTenantService.ENV_KEY, JSON.stringify(environment)
  );

  return environment;

}

const appInsightsFactory = () => {

  return environment;

}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducers),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer, stateKey: 'router' }),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    AppShellModule,
    CorePipesModule,
    PlatformModule,
    QuillModule.forRoot(),
    AskAiPanelModule
  ],
  providers: [
    {
      provide: ApplicationInsightsService,
      useFactory: appInsightsFactory,
      deps: [Environment]
    },
    { provide: Environment, useFactory: envFactory },
    { provide: MAT_DATE_LOCALE, useFactory: getLocale },
    { provide: LOCALE_ID, useFactory: getLocale },
    { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: [] },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    GlobalContextService,
    EventService,
    GlobalContextService,
    TelemetryService,
    ApplicationInsightsService,
    RegionGuard,
    NavigationService
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
