<div class="card-type-label" [ngClass]="getTitleHolderSize()">
    <div *ngIf="date.eventType.id === 2" class="standard-label">
        Event
    </div>
    <div *ngIf="date.eventType.id === 1" class="termination-label">
        Termination Event
    </div>
</div>

<div [ngClass]="getCardHolder()">

    <div class='column'>
        <div class="card-date" [ngClass]="getDateStyle()" [matTooltip]="dateTooltip"
            matTooltipClass="smz-tooltip-light">
            <div class="weekday-text">{{date.eventDateDayStr}}</div>
            <div class="date-text">{{date.eventDateDay}}</div>
        </div>
    </div>
    <div class='column'>
        <div class="filename" [class.clickable]="contractLinkActive" (click)="goToContract(date)"
            data-cy="date-filename" data-testid="date-filename">{{date.documentName}}</div>
        <div class="contract-title">{{date.tenantTemplateName}}</div>
        <div class="row contract-details">
            <div class="contract-var">Parties:</div>
            <div class="body-small truncate" [matTooltip]="date.parties">{{date.parties}}</div>
        </div>
        <div *ngIf="date.eventType.id === 1" class="row contract-details">
            <div class="contract-var">Notice period:</div>
            <div class="body-small truncate">{{date.noticePeriod}} days </div>
        </div>
        <div class="row contract-details">
            <div class="contract-var">Clause:</div>
            <div *ngIf="!date.clause" class="body-small truncate">None</div>
            <div *ngIf="date.clause?.displayName" class="body-small truncate" [matTooltip]="date.clause?.displayName">
                {{date.clause?.displayName}}
            </div>
        </div>
        <div class="row contract-details">
            <div class="contract-var">Custom reminder:</div>
            <div *ngIf="!date.reminderDates.length" class="body-small">None</div>
            <div *ngIf="date.reminderDates.length === 1" class="body-small truncate">
                {{date.reminderDates[0] | smzdate:'d MMM y'}}
            </div>
            <div *ngIf="date.reminderDates.length > 1" class="body-small truncate"
                [matTooltip]="getReminderDatesTooltip()">
                {{date.reminderDates[0] | smzdate: 'd MMM y'}} and {{date.reminderDates[1] | smzdate: 'd MMM y'}}
            </div>
        </div>
        <div class="row contract-details">
            <div class="contract-var">Description:</div>
            <div *ngIf="!date.description" class="body-small truncate">None</div>
            <div *ngIf="date.description" class="body-small truncate" [matTooltip]="date.description">
                {{date.description || 'None'}}
            </div>
        </div>
        <div *ngIf="displayEvergreen" class="row contract-details mt-1 evergreen">
            <mat-checkbox [checked]="date.isEvergreen" (change)="onEvergreenChanged($event.checked)">
                <span class="weight-heavy">Evergreen event</span>
            </mat-checkbox>
            <i matTooltip="Evergreen events are automatically replicated 12 months in advance once the event date has passed."
                matTooltipClass="smz-tooltip" class="helper-info icon icon-info"></i>
        </div>
    </div>

    <div *ngIf="canCalendarEventsUpdate" class="menu-holder">
        <app-ellipses-menu icon="ellipsis-vertical-outline" [commands]="commands"></app-ellipses-menu>
    </div>

</div>