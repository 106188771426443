<div [class.full-screen]="isFullScreen === true" class="ask-ai-panel host-{{host}}">

    <ng-container *ngIf="host === AskHost.WebApp">
        <div *ngIf="isFullScreen === false" class="header-bar">
            <app-icon (click)="goToAsk()" icon="resize-outline" />
            AI Assistant
            <app-icon (click)="closeAskAi()" icon="close-outline" />
        </div>
    </ng-container>

    <ng-container *ngIf="isLoading === true">
        <div class="spinner-container">
            <app-loading-spinner [visible]="true"></app-loading-spinner>
        </div>
    </ng-container>

    <ng-container *ngIf="isLoading === false">
        <div *ngIf="agentType !== AgentType.NotSet" class="sub-header-bar">
            <div class="agent-type-text">
                {{agentType === AgentType.Contract ? 'Contract question' : 'Repository analytics'}}
            </div>
            <app-block-button (click)="newThread()" display="New Thread" type="filter" width="140" />
        </div>
        <div id="scrollable-div" class="chat-context">

            <ng-container *ngIf="agentType === AgentType.NotSet">
                <div class="message-container">
                    <div class="message-text flex-row">
                        <div [@slideUpAnimation] class="message-wrapper flex-column">
                            <div class="message-content block">
                                Hi, what can I help you with?
                                <div class="message-options">
                                    <div (click)="setAgentType(AgentType.Contract)" class="message-option clickable">
                                        Contract question
                                    </div>
                                    <div *ngIf="hasAskAiRepository === true"
                                        (click)="setAgentType(AgentType.Repository)" class="message-option clickable">
                                        Repository analytics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="agentType !== AgentType.NotSet && messages?.length === 0">
                <div [@slideUpAnimation] class="welcome-text">
                    <div class="header-text block">
                        Hi, {{username}}!
                    </div>
                    <div class="sub-text block">
                        This would be a friendly get started message here like this. It might even have some starter
                        prompts below.
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="agentType !== AgentType.NotSet && messages?.length > 0">
                <div *ngFor="let message of messages" class="message-container"
                    [class.user]="message.role?.label === 'user'">
                    <div class="message-text flex-row">

                        <div [@slideUpAnimation] class="message-wrapper flex-column">

                            <ng-container *ngIf="message.state === undefined">

                                <ng-container *ngIf="isAssistantMessage(message)">
                                    <div class="message-content block" [innerHTML]="message.aiReply">
                                    </div>

                                    <div class="flex-row block">
                                        <div *ngIf="message.reasoning !== undefined && message.reasoning !== null && message.reasoning !== ''"
                                            (click)="message.reasoningOpen = !message.reasoningOpen"
                                            class="message-link mr-3 clickable">
                                            Explain
                                        </div>
                                        <div *ngIf="message.documents !== undefined && message.documents !== null && message.documents.length === 1"
                                            (click)="openContract(message.documents[0])" class="message-link clickable">
                                            View contract
                                        </div>
                                    </div>
                                    <div *ngIf="message.reasoningOpen === true" class="block reasoning-text">
                                        {{message.reasoning}}
                                        <div *ngIf="message.clauseMentioned !== undefined" class="clauses-used mt-2">
                                            <span>Clauses used: </span>
                                            <span *ngFor="let clause of message.clauseMentioned"
                                                (click)="openContractClause(message, clause.id)"
                                                class="mr-1 name clickable">{{clause.name}}
                                            </span>
                                        </div>
                                    </div>

                                </ng-container>

                                <div *ngIf="message.role.label === 'user'" class="message-content block"
                                    [class.user]="message.role.label === 'user'">
                                    {{message.prompt}}
                                </div>

                                <div
                                    *ngIf="message.chartDisplay !== undefined && message.chartDisplay !== null && message.chartDisplay !== ''">
                                    <div
                                        *ngIf="message.chartDisplay.data !== undefined && message.chartDisplay.data !== null && message.chartDisplay.data.datasets !== undefined">
                                        <div class="chart-container block">
                                            <div class="chart-title">
                                                {{message.chartDisplayTitle}}
                                            </div>
                                            <canvas baseChart [datasets]="message.chartDisplay.data.datasets"
                                                [labels]="message.chartDisplay.data.labels"
                                                [options]="message.chartDisplay.options"
                                                [type]="message.chartDisplay.type">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="message.state === 'pending' && message.role.label === 'user'">
                                <div class="message-content user block">
                                    {{message.prompt}}
                                </div>
                            </ng-container>

                            <ng-container *ngIf="message.state === 'pending' && isAssistantMessage(message)">
                                <div class="message-content block">
                                    <app-loading-spinner class="loading-spinner mt-3" [type]="'pale'"
                                        [visible]="true" />
                                </div>
                            </ng-container>

                        </div>

                    </div>
                </div>
            </ng-container>

        </div>
        <div *ngIf="agentType !== AgentType.NotSet" class="input-bar">
            <div class="input-container flex-row">
                <input [(ngModel)]="pendingMessage" (keydown.enter)="sendMessage()" type="text"
                    placeholder="Ask AI Assistant" />
                <app-icon (click)="sendMessage()" icon="send-outline" />
            </div>
            <div class="warning">
                AI can make mistakes. Double-check responses.
            </div>
        </div>
    </ng-container>
</div>