import { Component, ChangeDetectionStrategy } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { ExportDataScopeEnum } from '@summize/shared/framework';

@Component({
    selector: 'app-request-analytics-export-data-modal',
    templateUrl: 'request-analytics-export-data-modal.html',
    styleUrl: './request-analytics-export-data-modal.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestAnalyticsExportDataModalComponent {

    public fileType = '.csv';

    public dataOption: ExportDataScopeEnum;

    public exportOption = ExportDataScopeEnum;

    constructor(public dialogRef: MatDialogRef<RequestAnalyticsExportDataModalComponent>) { }

    public close() {

        this.dialogRef.close();

    }

    public export() {

        this.dialogRef.close({ dataOption: this.dataOption })
    }
}