import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export interface ChatResponse {
    aiReply?: string;
    prompt?: string;
    reasoning?: string;
    documents?: any;
    chartDisplay?: any;
    chartDisplayTitle?: string;
    role: { label: string }
    state?: string;
    clauses?: string;
}

export interface AskAgent {
    agentId: string;
    agentName: string;
    id: string;
}

export interface AskThread {
    agentId: string;
    threadId: string;
    id: string;
}

export interface AgentContext {
    agent: AskAgent;
    thread: AskThread;
}

export enum AgentType {
    Repository = 0,
    Contract = 1,
    NotSet = 99
}

@Injectable({ providedIn: 'root' })
export class AskAiService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async send(agentId: string, threadId: string, query: string): Promise<ChatResponse[]> {

        const payload = {
            message: query
        };

        return await this.asPromiseFrom(this.http.post<ChatResponse[]>(`${super.AskAiServiceEndpoint}/assistant/${agentId}/thread/${threadId}`, payload));

    }

    public async getThreadContent(agentId: string, threadId: string): Promise<ChatResponse[]> {

        return await this.asPromiseFrom(this.http.get<ChatResponse[]>(`${super.AskAiServiceEndpoint}/assistant/${agentId}/thread/${threadId}`));

    }

    public async getAgent(type: AgentType): Promise<AgentContext> {

        return await this.asPromiseFrom(this.http.get<AgentContext>(`${super.AskAiServiceEndpoint}/assistant/${type}`));

    }

    public async deleteThread(agentId: string, threadId: string) {

        return await this.asPromiseFrom(this.http.delete(`${super.AskAiServiceEndpoint}/assistant/${agentId}/thread/${threadId}`, {}));

    }

}