import { InjectionToken } from "@angular/core";
import { TenantModeEnum } from '@summize/shared/core';

export enum NoAccessReason {

    ForbiddenResource = 1,
    ContractAccess = 2
}

export enum RequestSubtype {
    Unknown = 0,
    Manage = 1,
    Review = 2,
    Create = 3,
    Inbox = 4,
    AskLegal = 5
}

export enum RequestStageMode {
    Linear,
    Flexible
}

export enum TaskType {
    System = 0,
    Review = 1,
    Approval = 2,
    General = 3
}

export enum ConnectorIntegrationTypes {
    Salesforce = 1,
    GDrive = 2,
    Jira = 3,
    Dynamics = 4,
    HubSpot = 8,
    Sharepoint = 9,
    SharePointBackup = 10
}

export enum PlatformAttachmentType {
    Outlook, Gmail, Salesforce, Jira, Hubspot
}

export interface User {
    apiBaseUrl: string;
    claims: string[],
    clientId?: string;
    companyLogoUrl: string;
    departmentId: string;
    departmentName: string;
    email: string;
    featureFlags: Array<string>;
    firstName: string;
    hasCompletedRegistration: boolean;
    hasExpired: boolean;
    isAdmin: boolean;
    isClientMatterEnabled: boolean;
    showGenericClientMatterLabels?: boolean;
    lastName: string;
    matterId?: string;
    tenantId?: string;
    tenantName: string;
    multiTenantId?: string;
    userId: string;
    id?: string;
    userType: number;
    isSummizeAuthentication: boolean;
    isFullTextSearchEnabled?: boolean;
    isAIEnabled?: boolean;
    isPremiumPlaybookEnabled?: boolean;
    isPremiumPlaybookAnalyticsEnabled?: boolean;
    isMultitenancySearchEnabled?: boolean;
    connectors?: Array<number>;
    timeZoneId: string;
    tenantMode: TenantModeEnum;
}

export interface AssetLogo {
    logoUrl: string;
}

export interface Event {
    event: string;
    context: any;
    scope?: string;
}

export interface EnvironmentVNext {
    production: boolean,
    apiUrl: string,
    applicationInsights: string,
    okta: {
        redirectUrl: string,
        authUrl: string,
        issuer: string,
        clientId: string;
    },
    locations: {
        storage: string,
        original: string,
        logoUpload: string;
    };
}

export interface LoginValues {
    idP?: string;
    scheme?: string;
    redirectUrl?: string;
    email?: string;
    autoLogin?: boolean;
}

export interface LogoutValues {
    removeEmail?: boolean;
    disableAutoLogin?: boolean;
}

export interface AuthServiceBase {
    initialise();
    login(values?: LoginValues);
    logout(values?: LogoutValues);
    isAuthenticated();
}

export enum TaskConditionOperator {
    AI
}

export interface TaskCondition {
    targetQuestionId: string,
    operator: TaskConditionOperator
    value: string;
    questionType: number,
}

export interface TaskNote {
    id: string;
    taskInstanceId: string;
    note: string;
    updatedAt: Date;
    createdBy: string;
    createdByName: string;
}

export interface TaskInstanceSchedule {
    id: string;
    frequency: ScheduleFrequency;
    interval: number;
    endDate: Date | null;
}

export interface TaskDefinitionModel {
    documentId?: string;
    documentName?: string;
    requestNumber?: number;
    requestSubType?: RequestSubtype;
    requestedBy?: string;
    task: {
        taskType: TaskType;
        dueInDays: string;
        dueDate?: string;
        dueDateUtc?: string;
        taskTitle: string;
        taskDescription: string;
        taskInstanceId?: string;
        taskDefinitionId?: string;
        isMandatory?: boolean;
        isComplete?: boolean;
        completedBy?: string;
        createdBy?: string;
        completionNotes?: string;
        taskInstanceSchedule?: TaskInstanceSchedule;
    };
    assignments: Array<{
        taskAssignmentDefinitionId?: string;
        userId?: string;
        groupId?: string;
        groupName?: string;
        userName?: string;
        canComplete?: boolean;
    }>;
    conditions?: Array<TaskCondition>;
    notes?: TaskNote[];
}

export interface TemplateQuestionAnswer {
    id: string;
    index: number;
    text: string;
    type: number;
    value: any;
    meta: any;
}

export interface AskLegalRequestModel {
    request: {
        documentSource?: any;
        documentId: string;
        name: string;
        templateId: string;
        answers: AskLegalAnswer[];
        attachments?: RequestAttachment[];
        connectorRef?: string;
        connector?: string;
    };
}

export interface TicketRequestModel {

    request: {
        ticketId?: string;
        descriptionText: string;
        detailText?: string;
        pageUrl?: string;
        currentUrl?: string;
        affectedUsers?: string;
        ticketType?: string;
        attachments?: HubspotAttachment[];
    };
}

export interface AskLegalAnswer {
    answerIndex: number;
    questionText: string;
    value: string;
}

export interface RequestAttachment {
    attachmentId: string;
    fileName: string;
    fileType: string;
    fileSizeBytes: number;
    requestSubtype: RequestSubtype;
    error?: any;
    createdAt?: Date;
    createdBy?: string;
    createdByName?: string;
    complete?: boolean;
    documentId?: string;
}

export interface HubspotAttachment {
    attachmentId: string;
    fileName: string;
    fileType: string;
    fileSizeBytes: number;
    error?: any;
    createdAt?: Date;
    createdBy?: string;
    createdByName?: string;
    complete?: boolean;
}

export interface ReviewRequestModel {
    request: {
        documentSource?: any;
        name: string;
        templateId: string;
        documentId: string;
        masterDocument?: any;
        linkedDocuments?: any[];
        attachments?: RequestAttachment[];
        answers?: ReviewAnswer[];
        connectorRef?: string;
        connector?: string;
        counterparties?: ESignatureRecipient[];
    };
}

export interface ReviewAnswer {
    answerIndex: number;
    questionText: string;
    value: string;
}

export interface CreateRequestModel {
    request: {
        templateId: string;
        name: string;
        counterparties?: ESignatureRecipient[];
    };
}

interface GenerateTemplateQuestionAnswerRequest {
    questionId: string;
    questionText: string;
    answer: string;
}

export interface GenerateCreateRequestModel {
    createTemplateId: string;
    name: string;
    source: string;
    questions: Array<{
        value: string;
        rawString: string;
        tokenId: string;
        questionType: any;
    }>;
    counterparties?: ESignatureRecipient[];
    templateQuestions: GenerateTemplateQuestionAnswerRequest[];
}


export interface QuestionAnswerSuggestion {
    key: string;
    value: string;
    name: string;
}

export enum UserCalendarSyncCalendarEvents {

    None = 0,
    AllVisible,
    Owned,

}

export enum UserCalendarSyncTasks {

    None = 0,
    Owned

}

export interface UserCalendarSyncModel {

    calendarEvents: UserCalendarSyncCalendarEvents;
    tasks: UserCalendarSyncTasks;

}

export interface StatusPillStatus {
    display: string;
    background: string;
}

export enum InboundIntegrationTypes {
    Docusign = 0,
    Generic = 1,
    AdobeSign = 2,
    MailGunInbox = 3,
    OneFlow = 4,
    Salesforce = 5
}

export enum OutboundIntegrationTypes {
    Empty = 0,
    Webhook = 1,
    Teams = 2,
    Slack = 3,
    Email = 4,
    DocusignShared = 5,
    DocusignPersonal = 6
}

export enum ESignatureRequestStatus {
    Pending = 1,
    CompletedSuccess = 2,
    CompletedFailure = 3,
    Deleted = 4
}

export interface OutboundIntegration {
    tenantId: string;
    departmentId: string;
    id: string;
    displayName: string;
    outboundIntegrationType: number;
}

export interface SimpleUser {
    tenantId: string;
    departmentId: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface SimpleUsers {
    users: SimpleUser[];
    totalUsers: number;
    filteredUsers: number;
}

export interface Group {
    groupId: string;
    tenantId: string;
    departmentId: string;
    groupName: string;
}

export interface Groups {
    groups: Group[];
    totalCount: number;
}

export interface AdobeSignAccountResponse {
    id: string;
    name: string;
}

export interface AdobeSignRecipient {
    email: string;
    exists?: boolean;
    invalid?: boolean;
}

export interface AdobeSignSignatureContext {
    recipients: ESignatureRecipient[];
    ccRecipients: ESignatureRecipient[];
    hasSignOrder: boolean;
    hasCcRecipients: boolean;
    isValid: boolean;
}

export interface DocusignUserAccount {
    email: string;
    accountId: string;
    accountName: string;
    baseUri: string;
    isDefault: boolean;
    organization?: DocusignAccountOrganization;
}

export interface DocusignAccessToken {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
}

export interface DocusignInitialInformationResponse {
    docusignAccessToken: DocusignAccessToken;
    docusignUserAccounts?: DocusignUserAccount[];
}

export interface AdobeSignEnvironment {
    clientId: string;
    authRedirectUrl: string;
    authScopes: string;
    oAuthBaseUrl: string;
}

export interface DocusignEnvironment {
    docusignIntegrationKey: string;
    docusignAuthBaseUrl?: string;
    personalAccounts: number;
    eSignatureAutoSendDetails?: ESignatureAutoSendDetail[];
}

export interface ESignatureAutoSendDetail {
    integrationId: string;
    requestSubtype: RequestSubtype;
    templateId: string;
}

export interface DocusignAccountOrganization {
    organization_id: string;
    links: DocusignAccountOrganizationLink[];
}

export interface DocusignAccountOrganizationLink {
    rel: string;
    href: string;
}

export enum EsignatureIntegrationType {
    Oneflow = 4,
    DocuSignShared = 5,
    DocuSignPersonal = 6,
    AdobeSignPersonal = 7
}

export enum EsignatureSigningOrder {
    InternalFirst = 1,
    CounterpartiesFirst = 2
}

export interface ESignatureMeta {
    folders: any;
    contractTypes: any;
    availableStatuses: any;
}

export interface ESignatureAutoSendModel {
    assignTo: string;
    clientId: string;
    clientName: string;
    matterId: string;
    matterName: string;
    contractTypeId: string;
    contractTypeName: string;
    repositoryDocumentStatusId: number;
    internalRecipients: ESignatureRecipient[];
    counterpartyRecipients: ESignatureRecipient[];
    notifications?: ESignatureAutoSendNotificationEntity[];
    integrationId: string;
    integrationDisplayName?: string;
    requestContext?: string;
    integrationType: EsignatureIntegrationType;
    signingOrder: EsignatureSigningOrder;
    signatureType?: string;
    requiredCounterparties: number;
    requestSubtype?: RequestSubtype;
    isEnabled: boolean;
    isValid: boolean;
    eSignaturePendingRequestContext?: ESignaturePendingRequestContext;
    autoSendVersion: ESignatureAutoSendVersion;
}
export interface ESignaturePendingRequestContext {
    internalRecipients?: ESignatureRecipient[];
    counterparties?: ESignatureRecipient[];
}

export interface ESignatureAutoSendNotificationEntity {
    id: string;
    notificationEntityType: number;
    notificationEntityId: string;
    displayName?: string;
    groupKey?: string;
}

export interface ESignatureRecipient {
    name: string;
    email: string;
    tokenId: string;
    signOrder: number;
    recipientType: ESignatureRecipientType;
    recipientOrigin: ESignatureRecipientOrigin;
    parentSections?: Array<any>;
    nameInvalid?: boolean;
    emailInvalid?: boolean;
    emailExists?: boolean;
    externalStatus?: string;
    externalStatusUpdatedAt?: Date;
    signatureType?: string,
    visible?: boolean
}

export enum ESignatureAutoSendVersion {
    V1 = 1,
    V2 = 2
}

export enum ESignatureRecipientLocationType {

    Internal = 1,
    Counterparty = 2
}

export enum ESignatureRecipientType {

    To = 1,
    Cc = 2,
    Bcc = 3
}

export enum ESignatureRecipientOrigin {

    Internal = 1,
    Counterparty = 2
}

export interface SendForSignatureResponse {
    eSignatureRequestId: string
    contractUrl: string;
}

// Dashboards Start
export interface WidgetDataResponse {
    elements: WidgetElement[];
}

export interface WidgetElement {
    id: string;
    name: string;
    description: string;
    title: string;
    widgetType: WidgetType;
    widgetCategory: WidgetCategory;
    widgetFilters: WidgetFilterRequest[];
}

export interface WidgetChartElement extends WidgetElement {
    totalRecords: number;
    data: WidgetChartData;
    options: any;
    kpis?: WidgetChartKpi[];
    widgetTimeFormat?: WidgetTimeFormat;
}

export interface WidgetChartKpi {
    label: string;
    displayValue: string;
    value: number;
    primaryAccentColor: string;
}

export enum WidgetTimeFormat {
    None = 0,
    Minutes = 1,
    Hours = 2,
    Days = 3,
    Weeks = 4
}

export interface WidgetChartConfigurationOptions {
    displayTotals: boolean;
}

export interface WidgetTableElement extends WidgetElement {
    pageSize: number;
    columns: string[];
    default: any;
    headers: any;
    sort: any;
    source: any;
}

export interface WidgetChartData {
    labels: string[];
    datasets: WidgetChartDataset[];
}

export interface WidgetChartDataset {
    label: string;
    data: number[];
}

export interface WidgetFilterRequest {
    field: number;
    fieldName: string;
    values: string[];
    widgetFilterType: WidgetFilterType;
}

export enum WidgetType {
    Chart = 1,
    Table = 2
}

export enum WidgetChartType {
    Bar = 1,
    StackedBar = 2,
    Line = 3
}

export enum WidgetCategory {
    Performance = 1,
    Volume = 2,
    Pending = 3
}

export enum WidgetFilterType {
    Users = 1,
    Groups = 2,
    Templates = 3,
    RequestType = 4,
    RequestSource = 5,
    AskLegalTemplates = 6,
    CreateTemplates = 7,
    ReviewTemplates = 8,
    RequestStage = 9
}

export enum WidgetFilterFieldNames {
    Requestor = 1,
    Owner = 2,
    Template = 3,
    Time = 4
}

export interface DashboardRequest {
    name: string;
    isDefault: boolean;
    context?: DashboardContext;
}

export interface DashboardsResponse {
    dashboards: DashboardResponse[];
}

export interface DashboardResponse {
    id: string;
    name: string;
    isDefault: boolean;
}

export interface DashboardDetailResponse {
    id: string;
    name: string;
    isDefault: boolean;
    widgets: WidgetResponse[];
}

export interface WidgetResponse {
    id: string;
    title: string;
    description: string;
    filterTags: string[];
    widgetCategory: WidgetCategory;
    elements: WidgetElement[];
    availableFilters: WidgetFilterResponse[];
    appliedFilters: WidgetFilterRequest[];
    supportsTimeFilter: boolean;
    supportsTimeFormat?: boolean;
    timeFormat?: WidgetTimeFormat;
    dateFilter: DateFilter;
}

export interface WidgetFilterResponse {
    data: any;
    field: WidgetFilterFieldNames;
    fieldName: string;
    isDriver: boolean;
    name: string;
    widgetFilterTypes: WidgetFilterType[];
}

export interface DateFilter {
    dateFrom: string;
    dateTo: string;
    type: DateTimeFilterPresetEnum;
}

export enum DateTimeFilterPresetEnum {
    Custom,
    Last14Days,
    Last28Days,
    Last3Months,
    Last6Months,
    Last12Months
}

export interface DashboardContext {
    widgets: WidgetRequest[];
}

export interface WidgetRequest {
    widgetId: string;
    appliedFilters: WidgetFilterRequest[];
}
// Dashboards End

export interface AuthenticationProvider {
    tenantIdpId: string;
    tenantId: string;
    displayName: string;
    scheme: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    users?: Array<AuthenticationProviderUser>;
}

interface AuthenticationProviderUser {
    userId: string;
    userName: string;
    email: string;
    link?: string;
}

export enum ClientMattersVisibilityEnum {
    None = 'None',
    Full = 'Full',
    Some = 'Some'
}

export enum TemplatePermissionType {
    NotSet = 0,
    ManuallyAddNewVersion = 1,
    WordForWeb = 2,
    SendForSig = 3
}

export interface TemplatePermission {
    type: TemplatePermissionType,
    target: string
}

export enum ScheduleFrequency {
    Weekly = 1,
    Monthly = 2,
    Yearly = 3
}

export enum DocumentSource {
    WebApp = 1,
    Teams,
    Slack,
    Word,
    Mobile,
    Chrome,
    Docusign,
    Generic,
    AdobeSign,
    Email,
    OneFlow,
    Salesforce,
    Jira,
    Outlook,
    Gmail,
    Zip,
    HubSpot,
    Sharepoint
}

export enum CalendarEventType {
    Termination = 1,
    Standard = 2
}

export interface PendingNotificationsResponse {
    pendingNotifications: PendingNotifications;
}

export interface PendingNotifications {
    showRequestAnalyticsMovedNotification: boolean;
}

export enum UserModalNotificationType {
    RequestAnalyticsMoved = 1
}

export interface NotificationModalRequest {
    userModalNotificationType: UserModalNotificationType;
}

export enum ExportDataScopeEnum {
    AllData = 1,
    FilteredData = 2
}

export enum ExportDataFileTypeEnum {
    Csv = 1
}

export const Environment = new InjectionToken<any>('environment.config');