import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LoadingSpinnerModule, TableModule } from '@summize/shared/components';
import { ChartWidgetModule } from '../chart-widget/chart-widget.module';
import { BlockButtonModule } from '../block-button/block-button.module';
import { EllipsesMenuModule } from '../ellipses-menu/ellipses-menu.module';
import { SelectBoxModule } from '../select-box/select-box.module';
import { DatetimeFilterModule } from '../datetime-filter/datetime-filter.module';
import { FilterBuilderModule } from '../filter-builder/filter-builder.module';
import { SelectedFilterModule } from '../selected-filter/selected-filter.module';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { CorePipesModule } from '../pipes/pipes.module';
import { SmzDatePipe } from '../pipes/date.pipe';
import { DropdownButtonModule } from '../dropdown-button/dropdown-button.module';
import { RequestAnalyticsExportDataModalModule } from '../request-analytics-export-data-modal/request-analytics-export-data-modal.module';

import { WidgetHostComponent } from './widget-host.component';
import { WidgetHostService } from './widget-host.service';

@NgModule({
    imports: [
        CommonModule,
        ChartWidgetModule,
        LoadingSpinnerModule,
        BlockButtonModule,
        EllipsesMenuModule,
        SelectBoxModule,
        DatetimeFilterModule,
        TableModule,
        FilterBuilderModule,
        SelectedFilterModule,
        SearchBarModule,
        CorePipesModule,
        DropdownButtonModule,
        MatTooltipModule,
        OverlayModule,
        RequestAnalyticsExportDataModalModule
    ],
    exports: [
        WidgetHostComponent
    ],
    declarations: [
        WidgetHostComponent
    ],
    providers: [
        SmzDatePipe,
        WidgetHostService
    ]
})
export class WidgetHostModule { }

export * from './widget-host.component';