import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  name: 'dev',
  apiUrl: 'https://api.summize.local',
  oktaAuthUrl: 'https://dev-938782.oktapreview.com',
  thankYouUrl: 'https://app.summize.local/thank-you',
  applicationInsights: '',
  registrationUrl: 'http://localhost:7071/api/registration',
  publicSiteUrl: 'https://staging.summize.it',
  config: {
    clientsPerPage: 10,
    clientMattersPerPage: 10,
  },
  featureFlags: [
    "PendingFileSignalR",
    "SignalR",
    "ContractsV2",
    "ContractsV2-ContractAnalytics",
    "IsAIEnabled",
    "Analytics",
    "PremiumPlaybook",
    "ReviewInformation",
    "UseDocumentStreamDownload",
    "ReviewInformation-FindAndReplace",
    "AgreementsV2",
    "RegenerateSummary",
    "MultiLanguage",
    "CalendarSync",
    "Relationships"
  ],
  auth: {
    service: "oidc",
    oidc: {
      authority: "https://identity.summize.local",
      clientId: "summizeui",
      clientSecret: "secret",
      signinRedirectUrl: "https://app.summize.local/signin-oidc",
      signoutRedirectUrl: "https://app.summize.local/signout-oidc"
    }
  },
  tokenRefreshUrl: 'https://app.summize.local/silent-renew.html',
  regions: [
    { name: 'Local', appUrl: 'https://app.summize.local', endpoint: 'https://api2.summize.local' }
  ]
};
