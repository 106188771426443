import { Injectable } from '@angular/core';

import { Observable, lastValueFrom } from 'rxjs';
import { intersection } from 'lodash-es';
import { SummizeStorage } from '@summize/shared/framework';

import { EMPTY_GUID, UserTypeEnum, TenantModeEnum } from '../constants';

export interface User {
    departmentId: any;
    userId: string;
    tenantId: string;
    apiBaseUrl: string;
    clientId: string;
    matterId: string;
    isClientMatterEnabled: boolean;
    showGenericClientMatterLabels?: boolean;
    claims?: any;
    featureFlags?: any;
    departmentName?: string;
    tenantName?: string;
    multiTenantId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    isFullTextSearchEnabled?: boolean;
    isAdmin?: boolean;
    isAIEnabled?: boolean;
    isPremiumPlaybookEnabled?: boolean;
    isPremiumPlaybookAnalyticsEnabled?: boolean;
    isMultitenancySearchEnabled?: boolean;
    userType: UserTypeEnum;
    connectors: Array<number>;
    timeZoneId: string;
    tenantMode: TenantModeEnum;
}

@Injectable({
    providedIn: 'root'
})
export class BaseTenantService {

    public static ENV_KEY = 'smz:env';

    private version = '1.0';

    public static get environment() {

        return new BaseTenantService().getEnvironment();

    }

    public get AskAiServiceEndpoint(): string {

        return `${BaseTenantService.environment.services?.askAi?.endpoint}/${this.getUser().multiTenantId}/api/v1.0`;

    }


    public getUser(): User {

        const u = SummizeStorage.getLocalItem('user');

        if (u !== undefined) {

            const cachedUser = JSON.parse(u) as User;

            return cachedUser;

        }

    }

    public get isPocUser() {

        const user = this.getUser();

        return user.tenantMode === TenantModeEnum.Poc
            && !user.email.toLowerCase().endsWith('summize.com');

    }

    public getApiUrl(): string {

        const environment = this.getEnvironment();

        return environment.apiUrl;

    }

    public getUserBaseUrl(version = false): string {

        const user = this.getUser();

        return version === true ? `${user.apiBaseUrl}${this.version}/` : user.apiBaseUrl;

    }

    public getDocumentPage(clientId: string, matterId: string, documentId: string, requestType = 'review'): string {

        if (clientId === EMPTY_GUID && matterId === EMPTY_GUID) {

            return `/quick-summary/contract/${requestType}/${documentId}`;

        }

        return `/quick-summary/contract/manage/${documentId}`;

    }

    public getRepositoryPage(params: any): string {

        let url = `contracts/repository/${EMPTY_GUID}?`;

        if (params !== undefined) {
            for (let key of Object.keys(params)) {

                url += `&${key}=${encodeURIComponent(params[key])}`;

            }
        }

        return url;

    }

    public getFolderPage(clientId: string, matterId: string, docTypeId: string = '0'): string {

        if (clientId === EMPTY_GUID && matterId === EMPTY_GUID) {

            return `/quick-summary/dashboard`;

        }

        return `/my-contracts/clients/${clientId}/matters/${matterId}/type/${docTypeId}`;

    }

    public getClaims(): string[] {

        const environment = this.getEnvironment();

        if (environment.claimsOverride !== undefined) {

            return environment.claimsOverride;

        }

        const user = this.getUser();

        return user?.claims ?? [];

    }

    public hasClaim(claim: string): boolean {

        const userClaims = this.getClaims();

        return userClaims.indexOf(claim) !== -1;
    }

    public hasClaims(claims: string[], all: boolean = true): boolean {

        const userClaims = this.getClaims();

        if (all) {

            const intersect = intersection(claims, userClaims);

            return (intersect.length === claims.length);

        } else {

            const intersect = intersection(claims, userClaims);

            return (intersect.length > 0);

        }
    }

    public hasFeatureFlag(flagName: string) {

        const user = this.getUser();

        const environment = this.getEnvironment();

        const featureFlags = user?.featureFlags ?? [];

        return featureFlags.indexOf(flagName) !== -1 || environment.featureFlags.indexOf(flagName) !== -1;

    }

    public async execute(obs: Observable<any>): Promise<any> {

        return await obs.toPromise()
            .then(data => Promise.resolve([undefined, data]))
            .catch(error => Promise.resolve([error, undefined]));

    }

    public getEnvironment(): any {

        return JSON.parse(SummizeStorage.getSessionItem(BaseTenantService.ENV_KEY));

    }

    public asPromise(producer: Observable<any>) {

        return lastValueFrom(producer);

    }

    public asPromiseFrom<T>(producer: Observable<T>) {

        return lastValueFrom(producer);

    }

}
