import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FeedbackPanelModule } from '@summize/shared/components';
import { AppHeaderModule, AskAiPanelModule, SideMenuModule, SlidePanelModule, ToastModule, UserPanelModule } from '@summize/shared/components-v2';

import { ContractCalendarModule } from '../../../modules/contract-calendar/contract-calendar.module';
import { CoreComponentsModule } from '../components.module';
import { AppShellComponent } from './app-shell.component';

@NgModule({
    imports: [
        CommonModule,
        AppHeaderModule,
        SideMenuModule,
        FeedbackPanelModule,
        CoreComponentsModule,
        SlidePanelModule,
        UserPanelModule,
        ContractCalendarModule,
        ToastModule,
        AskAiPanelModule
    ],
    exports: [
        AppShellComponent
    ],
    declarations: [
        AppShellComponent
    ]
})
export class AppShellModule { }
