import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AppComponent, ChangeDetectionHelper } from '@summize/shared/core';
import { FadeInAnimation } from '@summize/shared/framework';

import { ConversationInputComponent, ConversationMessageContent } from '../conversation-input/conversation-input.component';
import { ElipsisCommand } from '../ellipses-menu/ellipses-menu.component';
import { Conversation, ConversationMessage, ConversationStatus } from './conversation.service';

export enum EditingState {
    Initial,
    Creating,
    Editing
}

@Component({
    selector: 'app-conversation',
    templateUrl: 'conversation.html',
    styleUrls: ['./conversation.scss'],
    animations: [FadeInAnimation]
})
export class ConversationComponent extends AppComponent implements OnInit {

    public EditingState = EditingState;

    public ConversationStatus = ConversationStatus;

    @ViewChild("pending", { static: false })
    public creatingInputComponent: ConversationInputComponent;

    @ViewChild("editing", { static: false })
    public editingInputComponent: ConversationInputComponent;

    @Output()
    public onAddMessage: EventEmitter<{ conversation: Conversation, message: ConversationMessageContent }>;

    @Output()
    public onEditMessage: EventEmitter<{ conversation: Conversation, messageId: string, message: ConversationMessageContent }>;

    @Output()
    public onDelete: EventEmitter<Conversation>;

    @Output()
    public onDeleteMessage: EventEmitter<{ conversation: Conversation, messageId: string }>;

    @Output()
    public onStatusChange: EventEmitter<{ conversation: Conversation, status: ConversationStatus }>;

    @Input()
    public conversation: Conversation;

    @Input()
    public contractId: string;

    @Input()
    public readOnly: boolean = false;

    @Input()
    public users: any;

    @Input()
    public tasks: any;

    @Input()
    public borderless = false;

    public state: EditingState = EditingState.Initial;

    public showInput: boolean = false;

    public collapsed: boolean = false;

    public editingMessage: ConversationMessage;

    public cardCommands: Array<ElipsisCommand>;

    public get isEntityOwner() {

        const user = this.getUser();

        if (user === undefined || user === null) {

            return false;

        }

        return this.conversation.createdById == user?.userId;

    }

    constructor() {

        super();

        this.onAddMessage
            = new EventEmitter<{ conversation: Conversation, message: ConversationMessageContent }>();

        this.onEditMessage
            = new EventEmitter<{ conversation: Conversation, messageId: string, message: ConversationMessageContent }>();

        this.onDelete
            = new EventEmitter<Conversation>();

        this.onDeleteMessage
            = new EventEmitter<{ conversation: Conversation, messageId: string }>();

        this.onStatusChange
            = new EventEmitter<{ conversation: Conversation, status: ConversationStatus }>();

    }

    public ngOnInit(): void {

        if (this.isEntityOwner === true) {

            if (this.conversation.status === ConversationStatus.Active) {

                this.cardCommands = new Array<ElipsisCommand>(
                    {
                        text: 'Delete',
                        icon: 'trash-outline',
                        testId: 'ellipses-delete-conversation',
                        onClick: () => this.onDelete.emit(this.conversation),
                        ngIf: () => this.isEntityOwner
                    }
                );

                this.cardCommands.unshift({
                    text: 'Resolve',
                    icon: 'checkmark-done-circle-outline',
                    testId: 'ellipses-resolve-conversation',
                    onClick: () => this.onStatusChange.emit({
                        conversation: this.conversation,
                        status: ConversationStatus.Resolved
                    }),
                    ngIf: () => this.isEntityOwner
                });

            } else {

                this.cardCommands = new Array<ElipsisCommand>(
                    {
                        text: 'Delete',
                        icon: 'trash-outline',
                        testId: 'ellipses-delete-conversation',
                        onClick: () => this.onDelete.emit(this.conversation),
                        ngIf: () => this.isEntityOwner
                    }
                );

                this.cardCommands.unshift({
                    text: 'Reactivate',
                    icon: 'checkmark-done-circle-outline',
                    testId: 'ellipses-reactivate-conversation',
                    onClick: () => this.onStatusChange.emit({
                        conversation: this.conversation,
                        status: ConversationStatus.Active
                    }),
                    ngIf: () => this.isEntityOwner
                });

            }

        }

        this.readOnly =
            this.conversation.status === ConversationStatus.Resolved;

    }

    public isMessageOwner(message: ConversationMessage): boolean {

        const user = this.getUser();

        if (user === undefined || user === null) {

            return false;

        }

        return message.createdById == user.userId;

    }

    public hasValidMessage(control: ConversationInputComponent): boolean {

        var current = control?.getMessage();

        if (current === undefined) {

            return false;

        }

        const emptyHtmlStrings = [
            '<h1><br></h1>',
            '<h2><br></h2>',
            '<ol><li><br></li></ol>',
            '<ul><li><br></li></ul>'
        ];

        if (current.html !== null && emptyHtmlStrings.includes(current.html) === false && current.html !== this.editingMessage?.html) {
            
            return true;

        }

        return false;

    }

    public cancelMessage(): void {

        this.creatingInputComponent?.clear();

        this.editingInputComponent?.clear();

        this.editingMessage = undefined;

        this.state = EditingState.Initial;

    }

    public editMessage(message: ConversationMessage): void {

        this.state = EditingState.Editing;

        this.editingMessage = message;

    }

    public quoteMessage(message: ConversationMessage): void {

        this.state = EditingState.Creating;

        ChangeDetectionHelper.doNextCycle(() => {

            this.creatingInputComponent?.setContent(`${message.text}`);

        }, 500);

    }


    public addMessageComplete(): void {

        this.creatingInputComponent.clearLiveConversationText();

        this.onAddMessage.next({
            conversation: this.conversation,
            message: this.creatingInputComponent.getMessage()
        });

        this.state = EditingState.Initial;

    }

    public editMessageComplete(): void {

        this.onEditMessage.next({
            conversation: this.conversation,
            messageId: this.editingMessage.contractConversationMessageId,
            message: this.editingInputComponent.getMessage()
        });

        this.state = EditingState.Initial;

    }

    public deleteMessage(message: ConversationMessage): void {

        this.onDeleteMessage.next({
            conversation: this.conversation,
            messageId: message.contractConversationMessageId,
        });

        this.state = EditingState.Initial;

    }

}
