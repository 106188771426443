<div class="menu">

    <div *ngIf="showInbox === true" class="segment first">
        <a routerLink="/inbox" routerLinkActive="active" class="segment-item" data-cy="inbox">
            <app-icon [icon]="'file-tray-full-outline'"></app-icon>Inbox
        </a>
    </div>

    <div [class.first]="showInbox === false" class="segment">
        <a *ngIf="showRepository === true" (click)="goToManage()" class="segment-item" [class.active]="isOnManagePage()"
            data-cy="repository" data-testid="repository">
            <app-icon [icon]="'grid-outline'"></app-icon>Repository
        </a>
        <a *ngIf="showQuickSummary === true" routerLink="/quick-summary" [class.active]="isOnQuickSummary()"
            class="segment-item" data-cy="review">
            <app-icon [icon]="'flash-outline'"></app-icon>Review
        </a>

        <a *ngIf="showRequests === true" [routerLink]="['/quick-summary/repository']"
            [queryParams]="{ status: requestStatuses }" routerLinkActive="router-link-active"
            [class.active]="IsOnRequests()" class="segment-item" data-cy="requests" data-testid="requests">
            <app-icon [icon]="'flash-outline'"></app-icon>Requests
        </a>

        <a *ngIf="showCreate === true" routerLink="/create" class="segment-item" routerLinkActive="active"
            data-cy="create">
            <app-icon [icon]="'document-text-outline'"></app-icon>Create
        </a>
    </div>

    <div *ngIf="showManagement === true" class="segment">
        <a *ngIf="showAnalytics === true" (click)="goToAnalytics()" routerLinkActive="active" class="segment-item"
            data-cy="analytics" data-testid="analytics">
            <app-icon [icon]="'analytics-outline'"></app-icon>Analytics
        </a>
        <a *ngIf="showPlaybookManager === true" routerLink="/playbook-manager/manage" routerLinkActive="active"
            class="segment-item" data-cy="playbook">
            <app-icon [icon]="'book-outline'"></app-icon>Playbook
        </a>
        <a *ngIf="showPlaybooks === true" routerLink="/playbooks" routerLinkActive="active" class="segment-item"
            data-cy="playbook" data-testid="playbook">
            <app-icon [icon]="'book-outline'"></app-icon>Playbooks
        </a>
        <a *ngIf="showClauseManager === true" routerLink="/clause-manager/manage" routerLinkActive="active"
            class="segment-item" data-cy="clause-manager" data-testid="clause-manager">
            <app-icon [icon]="'file-tray-full-outline'"></app-icon>Clause Manager
        </a>
        <a *ngIf="showCustomFields === true" routerLink="/custom-fields/manage" routerLinkActive="active"
            class="segment-item" data-cy="custom-fields" data-testid="custom-fields">
            <app-icon icon="list-circle-outline"></app-icon>Custom Fields
        </a>
        <a *ngIf="showUserManagement === true" routerLink="/admin/user" routerLinkActive="active" class="segment-item"
            data-cy="users-&-permissions" data-testid="users-&-permissions">
            <app-icon [icon]="'people-outline'"></app-icon>Users & Permissions
        </a>
    </div>

    <div *ngIf="showSettings === true" class="segment last">
        <a routerLink="/settings" routerLinkActive="active" class="segment-item" data-cy="settings" data-testid="settings">
            <app-icon [icon]="'settings-outline'"></app-icon>Settings
        </a>
    </div>

</div>