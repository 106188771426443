export class ChartJsColorsHelper {

    public static CoreBrandColors = [
        '#2B158C',
        '#1BCDFF',
        '#F903FC',
        '#6D2BB3',
        '#FF8D1B',
        '#2CAAB5',
        '#795EEF',
        '#FF5E8B',
        '#FFAB6F',
        '#54D0A4',
        '#9153A5',
        '#FF8267',
        '#3EA2C2',
        '#FF84C7',
        '#D6337D',
        '#C0EC58',
        '#876CC2',
        '#F7C45E'
    ];

    // Support for 162 data points, which should be way more than we need
    // Anything over will show repeat colors, but user will have bigger issues
    public static getNextColor(index: number): string {

        const colorIndex = index % this.CoreBrandColors.length;

        let color = this.CoreBrandColors[colorIndex];

        if (index >= this.CoreBrandColors.length * 4) {

            return this.getNextColor(index - this.CoreBrandColors.length * 4);

        } else if (index >= this.CoreBrandColors.length * 3) {

            return this.getNextColor(index - this.CoreBrandColors.length * 3);

        } else if (index >= this.CoreBrandColors.length * 2) {

            const loopedIndex = index % (this.CoreBrandColors.length * 2);
            const lightenPercentage = 0.5;

            color = this.lightenColor(this.CoreBrandColors[loopedIndex % this.CoreBrandColors.length], lightenPercentage);

        } else if (index >= this.CoreBrandColors.length) {

            const loopedIndex = index % this.CoreBrandColors.length;
            const lightenPercentage = 0.3;

            color = this.lightenColor(this.CoreBrandColors[loopedIndex], lightenPercentage);
        }

        return color;
    }

    public static lightenColor(color: string, percentage: number): string {
        
        // Parse the hex color to RGB
        const rgb = this.hexToRgb(color);

        // Calculate the new lighter color
        const newRgb = {
            r: Math.min(Math.round(rgb.r + ((255 - rgb.r) * percentage)), 255),
            g: Math.min(Math.round(rgb.g + ((255 - rgb.g) * percentage)), 255),
            b: Math.min(Math.round(rgb.b + ((255 - rgb.b) * percentage)), 255)
        };

        // Convert RGB back to hex
        return this.rgbToHex(newRgb.r, newRgb.g, newRgb.b);
    }

    public static hexToRgb(hex: string): { r: number; g: number; b: number } {

        const bigint = parseInt(hex.slice(1), 16);

        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return { r, g, b };
    }

    public static rgbToHex(r: number, g: number, b: number): string {
        
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

}