import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CalendarEventsEvergreenService, UserService } from '@summize/shared/framework';
import { ElipsisCommand } from '@summize/shared/components-v2';
import { AppComponent } from '@summize/shared/core';

import { SmzDatePipe } from '../pipes/date.pipe';

export enum CardType {
    Termination = 1,
    Standard = 2,
}

@Component({
    selector: 'calendar-card',
    templateUrl: './calendar-card.component.html',
    styleUrls: ['./calendar-card.component.scss'],
})
export class CalendarCardComponent extends AppComponent implements OnInit {

    @Output()
    public cardEvent = new EventEmitter<any>();

    @Input()
    public date: any;

    public commands: Array<ElipsisCommand>;

    public canCalendarEventsUpdate = false;

    public contractLinkActive = true;

    public dateTooltip = '';

    public displayEvergreen: boolean = true;

    constructor(
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
        private calendarEventsEvergreenService: CalendarEventsEvergreenService,
        public datePipe: SmzDatePipe,
    ) { super(); }

    public ngOnInit(): void {

        this.canCalendarEventsUpdate = this.userService.hasClaims(['CanCalendarEventsUpdate']);

        this.contractLinkActive = !this.route.snapshot.url.map(x => x.path.toLowerCase()).includes(this.date.documentId);

        this.commands = [
            {
                text: 'Edit',
                icon: 'create-outline',
                testId: 'ellipses-edit-event',
                onClick: () => this.edit(),
            },
            {
                text: 'Download',
                icon: 'download-outline',
                testId: 'ellipses-download-event',
                onClick: () => this.download(),
            },
            {
                text: 'Delete',
                icon: 'trash-outline',
                testId: 'ellipses-delete-event',
                onClick: () => this.delete(),
            }
        ];

        this.displayEvergreen = this.isEventDateInFuture();

        this.dateTooltip = `${this.datePipe.transform(this.date.eventDateUtc, 'medium')} in your timezone.`;

    }

    public edit() {
        this.cardEvent.emit({
            name: 'edit',
            date: this.date,
        });
    }

    public delete() {
        this.cardEvent.emit({
            name: 'delete',
            date: this.date
        });
    }

    public download() {

        this.cardEvent.emit({
            name: 'download',
            date: this.date
        });

    }

    public goToContract(date: any) {

        if (this.contractLinkActive !== true) {

            return;

        }

        const route = `quick-summary/contract/manage/${date.documentId}`;

        this.router.navigate([route], { queryParams: { panelId: 'events' } });

    }

    public getDateStyle() {

        if (this.date.eventType.id === CardType.Termination) {

            return 'termination-date';

        }

        return 'standard-date';

    }

    public getTitleHolderSize() {

        if (this.date.eventType.id === CardType.Termination) {

            return 'termination-holder-size';

        }

        return 'standard-holder-size';

    }

    public getCardHolder() {

        if (this.date.eventType.id === CardType.Termination) {

            return 'termination-card-holder';

        }

        return 'card-holder';

    }

    public getReminderDatesTooltip() {

        return this.datePipe.transform(this.date.reminderDates[0], 'd MMM y') + ' and ' + this.datePipe.transform(this.date.reminderDates[1], 'd MMM y');

    }

    public async onEvergreenChanged(isEvergreen: boolean) {

        await this.calendarEventsEvergreenService.update(this.date.id, isEvergreen);

    }

    private isEventDateInFuture(): boolean {

        return new Date(this.date.eventDate) > new Date();

    }

}
