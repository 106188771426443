import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { AuthService, LocalStorageService, SummizeStorage } from '@summize/shared/framework';
import { environment } from "@summize/environment";
import { ApplicationInsightsService, BaseTenantService } from "@summize/shared/core";

import { Environment } from 'apps/web-app/src/environments/environment.model';

@Injectable({
  providedIn: 'root'
})
export class RegionGuard implements CanActivate {

  public static REGION_KEY = 'smz-region';

  public static REGION_INFO = 'region-data';

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private storageService: LocalStorageService,
    private appInsights: ApplicationInsightsService) { }

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (SummizeStorage.isIsolated() === true) {

      return true;

    }

    const currentRegion = this.storageService.getItem(RegionGuard.REGION_KEY);

    const smzRegion = next.queryParams['smz-region'];

    if (smzRegion !== null && smzRegion !== undefined) {

      if (smzRegion.length === 0) {

        this.storageService.removeItem(RegionGuard.REGION_KEY);

        this.storageService.removeItem(RegionGuard.REGION_INFO);

      } else {

        this.storageService.setItem(RegionGuard.REGION_KEY, smzRegion);

      }

    }

    const region = this.storageService.getItem(RegionGuard.REGION_KEY);

    if (region === undefined || region === null) {

      if (environment.regions.length === 1) {

        const def = environment.regions[0].endpoint;

        this.storageService.setItem(RegionGuard.REGION_KEY, def);

        await this.handleRegionSelectionComplete(def);

        return Promise.resolve(true);

      }

      return this.router.navigateByUrl('/region');

    }

    const currentEnvironment = this.getCurrentEnvironment();

    // Only update the region and environment if the region has changed or the environment has not been initialized
    if (currentRegion !== region || !currentEnvironment?.initialized) {

      await this.handleRegionSelectionComplete(region);

    }

    return Promise.resolve(true);

  }

  private async handleRegionSelectionComplete(region: string): Promise<void> {

    // Ste O 
    // Alot of temp changes in here that need to be removed when ask in properly implemented

    environment.apiUrl = region;

    const remoteEnvironment: any = await this.http.get(`${environment.apiUrl}/api/environment`).toPromise();

    let applyAskAiEnvironment = false;

    if (window.location.origin === 'https://app-ask.summize.com') {

      applyAskAiEnvironment = true;

      console.log(`Using Ask AI environment`);

      remoteEnvironment.summizeAppUrl = 'https://app-ask.summize.com';

      environment.services = {
        askAi: {
          endpoint: 'https://api-ask.summize.com'
        }
      };

    }

    if (window.location.origin === 'https://app.summize.com') {

      environment.services = {
        askAi: {
          endpoint: 'https://api-ask.summize.com'
        }
      };

    }

    if (remoteEnvironment.summizeAppUrl !== window.location.origin) {

      window.location.href = remoteEnvironment.summizeAppUrl + '?smz-region=';

    }

    if (environment.name === 'dev') {

      Object.keys(environment).forEach((key) => {

        if (environment[key] !== null) {

          remoteEnvironment[key] = null;

        }

      });

    }

    Object.keys(remoteEnvironment).forEach((key) => {

      if (remoteEnvironment[key] !== null) {

        if (typeof remoteEnvironment[key] === 'string' && environment[key] !== null) {

          if (remoteEnvironment[key].trim().length > 0) {

            environment[key] = remoteEnvironment[key];

          }

        } else {

          environment[key] = remoteEnvironment[key];

        }
      }

    });

    if (applyAskAiEnvironment) {

      environment.auth.oidc.signinRedirectUrl = 'https://app-ask.summize.com/signin-oidc';

      environment.auth.oidc.signoutRedirectUrl = 'https://app-ask.summize.com/signout-oidc';

      if(environment.featureFlags === undefined){
        
        environment.featureFlags = [];

      }

      environment.featureFlags.push('HasAskAi');

    }

    environment.initialized = true;

    SummizeStorage.setSessionItem(
      BaseTenantService.ENV_KEY, JSON.stringify(environment)
    );

    this.appInsights.init(environment.applicationInsights);

    await this.auth.init();

  }

  private getCurrentEnvironment(): Environment | undefined {

    const environmentSessionItem = SummizeStorage.getSessionItem(BaseTenantService.ENV_KEY);

    if (environmentSessionItem) {

      try {

        return JSON.parse(environmentSessionItem) as Environment;

      }
      catch (error) {

        console.error("Invalid JSON format in environment configuration:", error);
        return undefined;

      }

    }

    return undefined;

  }
}
