import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgChartsModule } from 'ng2-charts';

import { AskAiPanelComponent } from './ask-ai-panel.component';
import { IconModule } from '../icon/icon.module';
import { BlockButtonModule } from '../block-button/block-button.module';
import { AskAiService } from './ask-ai.service';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        BlockButtonModule,
        FormsModule,
        NgChartsModule,
        LoadingSpinnerModule
    ],
    exports: [
        AskAiPanelComponent
    ],
    declarations: [
        AskAiPanelComponent
    ],
    providers: [
        AskAiService
    ]
})
export class AskAiPanelModule { }

export * from './ask-ai-panel.component'