import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';

import { BlockButtonModule } from '../block-button/block-button.module';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { RequestAnalyticsExportDataModalComponent } from './request-analytics-export-data-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DialogShellModule,
        BlockButtonModule,
        MatRadioModule
    ],
    exports: [
        RequestAnalyticsExportDataModalComponent
    ],
    declarations: [
        RequestAnalyticsExportDataModalComponent
    ]
})
export class RequestAnalyticsExportDataModalModule { }

export * from './request-analytics-export-data-modal.component'