<div class="header content">

  <ul class="row">

    <li class="header__item menu__trialContainer" *ngIf="isTrial && showTrial">
      <app-document-counter></app-document-counter>
    </li>

    <li *ngIf="hasAskAi === true" class="header__item row mr-1 menu__search app-icon ai" (click)="showAssistant()">
      <app-icon icon="sparkles-outline" class="mr-2"></app-icon> AI Assistant
    </li>

    <li class="header__item row mr-1 menu__search app-icon" (click)="showFeedback()">
      <app-icon *ngIf="customerHubEnabled === false" icon="chatbox-outline"></app-icon>
      <app-icon *ngIf="customerHubEnabled === true" icon="help-circle-outline"></app-icon>
    </li>

    <li *appClaimsShow="[Claims.Assignation.CanAssignedToSelfRead]" (click)="showPanel(0)"
      class="header__item row mr-1 menu__search app-icon">
      <app-icon icon="person-outline"></app-icon>
    </li>

    <li *appClaimsShow="[Claims.Calendar.CanCalendarEventsRead]" (click)="showPanel(1)"
      class="header__item row mr-1 menu__search app-icon">
      <app-icon icon="calendar-outline"></app-icon>
    </li>

    <li class="header__item">
      <app-user-profile></app-user-profile>
    </li>

  </ul>
</div>