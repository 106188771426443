<app-dialog-shell [showHeader]="true" [showClose]="false" (onClose)="close()">


    <div class="content-area">
        <div class="page1">
            <span class="header mb-24">
                <h2>Export as {{fileType}}</h2>
            </span>

            <p class="mb-24">Select an option to export.</p>

            <div>
                <mat-radio-group class="mb-24" [(ngModel)]="dataOption">
                    <mat-radio-button [value]="exportOption.AllData">
                        <span>All data</span>
                        <span class="span-description">(exports all available data, disregarding any filters)</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="exportOption.FilteredData">
                        <span>Filtered data</span>
                        <span class="span-description">(exports the filtered data shown in the table)</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="commands">

        <app-block-button class="mr-a link" [disabled]="dataOption === undefined" (click)="export()" display="Export"
            type="v2-primary" width="140" data-cy="modal-confirm">
        </app-block-button>

        <app-block-button class="link" display="Cancel" type="v2-blue" width="140" data-cy="modal-cancel"
            (click)="close()">
        </app-block-button>
    </div>
</app-dialog-shell>