<div class="elip-container {{style}}">
    <app-icon [matMenuTriggerFor]="menu" [icon]="icon" data-cy="ellipses-menu" data-testid="ellipses-menu"></app-icon>
</div>

<mat-menu #menu="matMenu" [class]="'elip-menu'">

    <ng-container *ngFor="let c of commands">

        <ng-container *ngIf="c.claim !== undefined">
            <button *appClaimsShow="[c.claim]" [disabled]="c.isDisabled === undefined ? false : c.isDisabled()" mat-menu-item (click)="fire(c)">
                <app-icon *ngIf="c.icon" [icon]="c.icon" [attr.data-testid]="c.testId"></app-icon>
                <span>{{c.text}}</span>
            </button>
        </ng-container>

        <ng-container *ngIf="c.claim === undefined && (c.ngIf === undefined ? true : c.ngIf())">
            <button mat-menu-item (click)="fire(c)" [disabled]="c.isDisabled === undefined ? false : c.isDisabled()">
                <app-icon *ngIf="c.icon" [icon]="c.icon" [attr.data-testid]="c.testId"></app-icon>
                <span>{{c.text}}</span>
            </button>
        </ng-container>

    </ng-container>

</mat-menu>