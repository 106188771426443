import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AppComponent, ChangeDetectionHelper, FeatureFlags, IsNotEmptyString } from '@summize/shared/core';
import { EventService, FadeInAnimation, ShowAssistant, SlideUpAnimation, SummizeStorage } from '@summize/shared/framework';

import { AgentContext, AgentType, AskAiService, ChatResponse } from './ask-ai.service';
import { ChartJsColorsHelper } from '../chart-widget/chartjs-colors.helper';

const ASK_AI_AGENT_TYPE = 'smz:ask:agentType';

export enum AskHost {
    WebApp,
    Outlook
}

interface ChatResponseDisplay {
    reasoningOpen?: boolean;
    clauseMentioned?: Array<{ name: string, id: string }>;
}

interface ChatResponseViewModel extends ChatResponse, ChatResponseDisplay { }

@Component({
    selector: 'app-ask-ai-panel',
    templateUrl: 'ask-ai-panel.html',
    styleUrl: './ask-ai-panel.scss',
    animations: [FadeInAnimation, SlideUpAnimation]
})
export class AskAiPanelComponent extends AppComponent implements OnInit {

    public AskHost = AskHost;

    @Input()
    public host: AskHost = AskHost.WebApp;

    @Output()
    public OnViewContract: EventEmitter<string> = new EventEmitter<string>();

    public AgentType = AgentType;

    public isLoading = false;

    public threadId: string;

    public messages: ChatResponseViewModel[] = [];

    public pendingMessage: string;

    public agentType: AgentType = AgentType.NotSet;

    public hasAskAiRepository = false;

    public get isValidThread(): boolean {

        return IsNotEmptyString(this.threadId);

    }

    public get isFullScreen(): boolean {

        return window.location.pathname.toLowerCase().endsWith('/ask');

    }

    private agentContext: AgentContext;

    public constructor(private router: Router, private service: AskAiService, private eventService: EventService) {

        super();

    }

    public async ngOnInit() {

        this.hasAskAiRepository = this.hasFeatureFlag(FeatureFlags.HasAskAiRepository);

        this.isLoading = true;

        if (this.hasAskAiRepository === false) {

            SummizeStorage.setSessionItem(ASK_AI_AGENT_TYPE, AgentType.Contract.toString());

        }

        const agentType = SummizeStorage.getSessionItem(ASK_AI_AGENT_TYPE);

        if (agentType !== undefined && agentType !== null) {

            this.agentType = parseInt(agentType);

        }

        if (this.agentType === AgentType.NotSet) {

            this.isLoading = false;

            return;

        }

        this.agentContext = await this.service.getAgent(this.agentType);

        this.threadId = this.agentContext.thread.id;

        const result = await this.service.getThreadContent(
            this.agentContext.agent.id,
            this.agentContext.thread.id
        );

        this.makeMessages(result);

        ChangeDetectionHelper.doNextCycle(() => {

            this.scrollToBottom();

        });

        this.isLoading = false;

    }

    public goToAsk() {

        this.router.navigate(['/ask']);

    }

    public async setAgentType(type: AgentType) {

        SummizeStorage.setSessionItem(ASK_AI_AGENT_TYPE, type.toString());

        this.agentType = type;

        await this.ngOnInit();

    }

    public clearAgentType() {

        SummizeStorage.removeSessionItem(ASK_AI_AGENT_TYPE);

        this.agentType = AgentType.NotSet;

        this.ngOnInit();

    }

    public async sendMessage(prompt: string = undefined): Promise<void> {

        const message = prompt ?? this.pendingMessage;

        if (this.isValidThread === false) {

            await this.newThread();

        }

        this.messages.push({
            role: { label: 'user' },
            prompt: message,
            state: 'pending'
        });

        setTimeout(() => {

            this.messages.push({
                role: { label: 'Assistant' },
                state: 'pending'
            });

        }, 300);

        ChangeDetectionHelper.doNextCycle(() => {

            this.pendingMessage = '';

            this.scrollToBottom();

        });

        const result = await this.service.send(
            this.agentContext.agent.id,
            this.agentContext.thread.id,
            message);

        this.applyNewMessage(result);

        ChangeDetectionHelper.doNextCycle(() => {

            this.scrollToBottom();

        });

    }

    private makeMessages(messages: ChatResponse[]): any {

        this.messages = messages.reverse().map((item: ChatResponse) => {

            return this.buildMessage(item);

        });

    }

    private applyNewMessage(item: ChatResponse[]): ChatResponse {

        if (this.messages[this.messages.length - 1]?.state === 'pending') {

            this.messages.pop();

        }

        const newMessage = this.buildMessage(item[0]);

        this.messages.push(newMessage);

        return newMessage;

    }

    private buildMessage(item: ChatResponseViewModel): ChatResponse {

        item.reasoningOpen = false;

        if (item.documents !== undefined && item.documents !== null && item.documents !== '') {

            item.documents = item.documents.split(',');

        }

        if (item.clauses !== undefined && item.clauses !== null && item.clauses !== '') {

            const clauses = item.clauses.split(',');

            item.clauseMentioned = clauses.map((clause: string) => {

                const parts = clause.split(':');

                return {
                    name: parts[0],
                    id: parts[1]
                }

            });

        }

        if (item.chartDisplay) {

            try {

                item.chartDisplay = JSON.parse(item.chartDisplay);

                item.chartDisplay.plugins = {
                    legend: { display: false }
                };

                item.chartDisplay.cardTitle = item.chartDisplayTitle;

                if (item.chartDisplay.data) {

                    item.chartDisplay.data.datasets.forEach((dataset, index) => {

                        dataset.backgroundColor = ChartJsColorsHelper.CoreBrandColors;

                    });

                }

                if (item.chartDisplay.options?.title) {

                    delete item.chartDisplay.options.title;

                }

                if (item.chartDisplay.type === 'bar') {

                    item.chartDisplay.options = {
                        ...item.chartDisplay.options,
                        plugins: {
                            ...item.chartDisplay.options?.plugins,
                            legend: { display: false }
                        },
                        scales: {
                            r: {
                                ticks: { display: false }
                            },
                            y: { display: true },
                            x: { display: true }
                        }
                    };

                }

            } catch (error) {

                console.error(error);

                item.aiReply = item.aiReply ?? "Oops! Something went wrong. Please try again.";

                item.chartDisplay = undefined;

            }
        }

        return item;

    }

    public isAssistantMessage(message: ChatResponse): boolean {

        return message.role.label.toLowerCase() === 'assistant';

    }

    public async newThread(): Promise<void> {

        await this.service.deleteThread(this.agentContext.agent.id,
            this.agentContext.thread.id
        );

        await this.ngOnInit();

    }

    public closeAskAi() {

        this.eventService.despatch(ShowAssistant);

    }

    public openContract(contract: string) {

        if (this.host === AskHost.Outlook) {

            this.OnViewContract.emit(contract);

        } else {

            this.router.navigate(['/contract', contract]);

        }

    }

    public openContractClause(message: ChatResponseViewModel, clause: string) {

        if (message.documents == undefined || message.documents == null) {

            return;

        }

        const contract = message.documents[0];

        this.router.navigate(['/contract', contract], {
            queryParams: {
                panelId: 'clauses',
                clauseId: clause
            }
        });

    }

    private scrollToBottom() {

        const div = document.getElementById('scrollable-div');

        div.scrollTop = div.scrollHeight;

    }
}
