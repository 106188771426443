export const DeleteSelectedTextEvent = 'DeleteSelectedText';
export const ExpandUserPanel = 'ExpandUserPanel';
export const GetSelectedTextEvent = 'GetSelectedText';
export const HighlightDocumentParagraphs = 'HighlightDocumentParagraphs';
export const RefreshActiveTable = 'RefreshActiveTable';
export const RefreshPendingContractCount = 'RefreshPendingContractCount';
export const RefreshQuickSummaryCount = 'RefreshQuickSummaryCount';
export const SelectedTextEvent = 'SelectedText';
export const SelectTextEvent = 'SelectText';
export const SetContext = 'SetContext';
export const SetEllipsisMenu = 'SetEllipsisMenu';
export const ShowContract = 'ShowContract';
export const ShowContractInsight = 'ShowContractInsight';
export const ShowFeedback = 'ShowFeedback';
export const ShowGlobalSearchResult = 'ShowGlobalSearchResult';
export const ShowPanelEvent = 'ShowPanel';
export const ShowToastMessage = 'ShowToastMessage';
export const UploadNewContract = 'UploadNewContract';
export const UserLoggedInEvent = 'UserLoggedInEvent';
export const WebsocketMessage = 'WebsocketMessage';
export const ShowAssistant = 'ShowAssistant';