import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';
import { ExportDataFileTypeEnum, ExportDataScopeEnum } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class WidgetHostService extends BaseTenantService {

    constructor(private httpClient: HttpClient) {

        super();
    }

    public async exportDataAsCsv(widgetId: string, exportDataScope: ExportDataScopeEnum, dateFrom: string, dateTo: string, filters: any, dateFilterPreset: any, timeFormat: any): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}requestanalytics/widgets/${widgetId}/export`;

        const payload = {
            filters: filters,
            dateFrom: dateFrom !== null ? dateFrom : undefined,
            dateTo: dateTo !== null ? dateTo : undefined,
            dateFilterPreset: dateFilterPreset !== null ? dateFilterPreset : undefined,
            timeFormat: timeFormat,
            exportOptions: {
                exportScope: exportDataScope,
                fileType: ExportDataFileTypeEnum.Csv
            }
        };

        return await this.asPromise(this.httpClient
            .post(
                url,
                payload,
                {
                    headers: new HttpHeaders({ 'Accept': 'text/csv', 'Content-Type': 'application/json' }),
                    responseType: 'blob'
                }));

    }
}